import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from 'socialbureau-uikitset'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import useToast from 'hooks/useToast'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
// import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  const { toastError} = useToast()
  
  useEffect(() => {
    if (!account && ( window.localStorage.getItem('accountStatus') || window.localStorage.getItem('connectorId' )) ) {
      connect('injected')
    }

    // non-chrome browser dev problem (unfix)
    // if(window.ethereum.networkVersion && window.ethereum.networkVersion !== '96') toastError('Wallet Rejected', `Please connect wallet on Bitkub Chain (BKC)`)
    
  }, [account, connect, toastError])

  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useFetchPublicData()

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Farms />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      {/*      <NftGlobalNotification /> */}
    </Router>
  )
}

export default React.memo(App)
