import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'JUTC',
    lpAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe', // JUTC
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe', // JUTC
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    isTokenOnly:true
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'JUTC-BUSD',
    lpAddresses: {
      97: '0xa4cfaba8443971bd4c9ece4912a772d64e4445cc',
      56: '0xf92ff44ba6d90bebb01886bc24c3cbf4d5817b47',
    },
    tokenSymbol: 'JUTC',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe',
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]



export default farms
